import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
} from "@react-google-maps/api";
import axios from "axios";
import { useState, useMemo, useEffect } from "react";

function PopupContent({ name, connectedPorts, numberOfPorts }) {
  return (
    <div>
      <h3 style={{ textAlign: "center" }}>
        ODB - {name} | Ports - {numberOfPorts}
      </h3>
      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
        {connectedPorts.map((port, index) => {
          let color = "black"; // default color

          if (port.port && port.serviceStatus === "active") {
            color = "green";
          } else if (port.serviceStatus === "inactive") {
            color = "#FF5733";
          }

          return (
            <li key={index} style={{ marginBottom: "10px", color: color }}>
              <span style={{ fontWeight: "bold" }}>{port.port}</span>-{" "}
              {port.fistName} {port.lastName} - {port.uniqueId || ""}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

const MapComponent = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const center = useMemo(() => ({ lat: 18.057798, lng: -76.96549 }), []);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [clickedCoords, setClickedCoords] = useState(null);
  const [locations, setLocations] = useState([]);
  const [numberofODB, setnumberofODB] = useState(0);

  const [obdInformation, setObdInformation] = useState([]);
  const [customerInformation, setCustomerInformation] = useState([]);

  const fetchData = () => {
    axios
      .get("https://bessnet-api.onrender.com/api/v1/odbs")
      .then((response) => {
        setObdInformation(response.data.data.obdInformation);
        setCustomerInformation(response.data.data.customerInformation);
        setnumberofODB(response.data.data.obdInformation.length);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  useEffect(() => {
    fetchData(); // Fetch the data immediately when component mounts

    const intervalId = setInterval(() => {
      fetchData();
    }, 5000); // Fetch data every 5 seconds

    return () => clearInterval(intervalId); // Clear the interval when the component is unmounted
  }, []);

   // onClick handler for the ODB marker
   const handleOdbClick = (location) => {
    setSelectedLocation({ type: 'odb', data: location });
  }

  // onClick handler for the customer marker
  const handleCustomerClick = (customer) => {
    setSelectedLocation({ type: 'customer', data: customer });
  }

  return (
    <div className="App">
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
          zoom={10}
          onClick={(e) => {
            setSelectedLocation(null);  // Reset the selected location
            setClickedCoords({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          }}
        >
          {obdInformation.map((location) => (
            <Marker
              key={location.id}
              position={{
                lat: parseFloat(location.latitude),
                lng: parseFloat(location.longitude),
              }}
              onClick={() => handleOdbClick(location)}
            />
          ))}

          {customerInformation.map((customer) => (
            <Marker
              key={customer.id}
              position={{
                lat: parseFloat(customer.latitude),
                lng: parseFloat(customer.longitude),
              }}
              onClick={() => handleCustomerClick(customer)}
              icon={{
                path: google.maps.SymbolPath.CIRCLE,
                scale: 5, // size of the circle
                fillColor: "blue",
                fillOpacity: 1,
                strokeWeight: 0,
              }}
            />
          ))}

          {selectedLocation && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedLocation.data.latitude),
                lng: parseFloat(selectedLocation.data.longitude)
              }}
              onCloseClick={() => setSelectedLocation(null)}
            >
              {selectedLocation.type === 'odb' ? (
                <PopupContent
                  name={selectedLocation.data.odbName}
                  connectedPorts={selectedLocation.data.connectedPorts}
                  numberOfPorts={selectedLocation.data.numberOfPorts}
                />
              ) : (
                <div>
                <p style={{textAlign:"center"}}><strong>ODB:</strong> {selectedLocation.data.odbName}</p>
                  <p><strong>Name:</strong> {selectedLocation.data.fullName}</p>
                  
                </div>
              )}
            </InfoWindow>
          )}
        </GoogleMap>
      )}

      {clickedCoords && (
        <div className="coords-display">
          Lat: {clickedCoords.lat.toFixed(6)}, Lng:{" "}
          {clickedCoords.lng.toFixed(6)}
        </div>
      )}
      <div className="total-display">
        {console.log(numberofODB)}
        Total No of ODB: {numberofODB}
      </div>
    </div>
  );
};

export default MapComponent;
