// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
  width: 100%;
  height: 100vh;  /* Adjust as needed */
}

.coords-display {
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.total-display{
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}


  .port-online {
    color: green;
    font-weight: bold;
}

.port-offline {
    color: red;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa,GAAG,qBAAqB;AACvC;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;;EAGE;IACE,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB","sourcesContent":[".map-container {\n  width: 100%;\n  height: 100vh;  /* Adjust as needed */\n}\n\n.coords-display {\n  position: absolute;\n  top: 60px;\n  right: 10px;\n  background-color: #fff;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n.total-display{\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  background-color: #fff;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n\n\n  .port-online {\n    color: green;\n    font-weight: bold;\n}\n\n.port-offline {\n    color: red;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
